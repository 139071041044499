import Box from "@material-ui/core/Box";
import BusinessIcon from '@material-ui/icons/Business';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {AppBar, Container, Toolbar} from "@material-ui/core";
import React from "react";

const BrandAccountCreated = () =>{

    const backToLogin = () =>{
        window.location.href = "/";
    }

    return <Box>
        <Box bgcolor={'#000000'} mb={2}>
            <AppBar color={'transparent'} position={'relative'}>
                <Toolbar>
                    <img height={'34px'} src={"https://sitecdn.tvpage.com/static/images/creatable_logo_white.svg"} alt={'Creatable'}/>
                </Toolbar>
            </AppBar>
        </Box>
        <Container maxWidth={'xs'}>
            <Box display={'flex'} justifyContent={'center'} pb={1}>
                <BusinessIcon style={{width: '64px', height: '64px'}} />
            </Box>
            <Typography variant={'h5'} align={'center'}>Your account is now active!</Typography>
            <Box pt={2} pb={2}>
                <Button onClick={backToLogin} color={'default'} disableRipple style={{backgroundColor: '#000000', color: '#ffffff'}} fullWidth variant={'contained'}>
                    Continue
                </Button>
            </Box>
            <Box>
                <Typography align={'center'}>Still having trouble?</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Link href={'mailto:support@creatable.io'}>Contact us</Link>
            </Box>
        </Container>
    </Box>
}

export default BrandAccountCreated;